<template>
    <div>
		<label for="unlimited" >Number of editions: <span class="required-asterisk">* </span></label>
		<div class="ps-3">
			<div class="inline-flex items-center checkbox-container text-gray-700 mb-3">
					<input id="unlimited" :disabled="readOnly" name="limited" type="radio" :checked="inputLimited === 0" value="0" @input="inputLimited = 0" @change="show_panel(true)"  class="focus:ring-primary h-4 w-4 text-primary border-gray-400 rounded">
					<label for="unlimited" >Unlimited editions available</label>
			</div>
			<div class="inline-flex items-center checkbox-container text-gray-700">
					
					<input id="limited" :disabled="readOnly" name="limited" type="radio" :checked="inputLimited === 1" @input="inputLimited = 1" value="1" @change="show_panel(false)"  class="focus:ring-primary h-4 w-4 text-primary border-gray-400 rounded">
					<label for="limited" >Limited edition run?</label>
			</div>
			<div v-if="showPanel" class="mt-4 text-gray-700">
				<label for="stock_no" class="block font-medium text-sm text-gray-700">Stock Number</label>
				<input id="stock_no" :readonly="readOnly"  class="shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" type="number" name="stock_no" v-model="inputStockNo" required />
				<label for="total_produced" class="mt-3 block font-medium text-sm text-gray-700 ">Total number of Limited Edition produced</label>
				<input id="total_produced" :readonly="readOnly"  class="shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" type="number" name="total_produced" v-model="inputTotalProduced" required />
			</div>
        </div>

	</div>
</template>
<script>
    export default {
		name: 'limitedshowpanel',
		props: ["limited", "stockNo", 'readOnly', 'totalProduced'],
		data: function() {
			return{
				inputLimited: null,
				inputStockNo: this.stockNo,
				inputTotalProduced: this.totalProduced,
				showPanel: false,
			}
		},
		mounted: function () {
			this.inputLimited = (this.limited === '' ? null : Number(this.limited))
			this.show_panel(true)
		},
		methods: {
			show_panel(override) {
				if (!this.readOnly || override) {
					if (this.inputLimited) {
						this.showPanel = true
					}
					else {
						this.showPanel = false
						this.inputStockNo = ''
						this.inputTotalProduced = ''
					}
				}
			}
		}
    }
</script>