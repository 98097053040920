<template>
    <div class="row">
        <div id="message-box" class="col-12">
            <div class="w-full">
                <div v-for="(message, key) in data_messages " :key="key" class="my-10 flex" :class="message.from_user_id == user ? 'flex-row-reverse' : 'flex-row'">
                    <div class="max-w-1/2   px-4 py-2 rounded-md shadow-xl" :class="message.from_user_id == user ? 'text-right' : ''">
                        <label class="block font-medium text-xs text-gray-700">{{message.readable_date}}</label>
                        <p class="text-lg">{{message.message}}</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="mt-4 col-12">
            <div class="form-group mb-5">
            <label>Enter your message...</label>
            <textarea rows="5" class="form-control" v-model="message"></textarea>
            </div>
            <button class="btn btn-outline-light float-end" @click="sendMessage">Send</button>
        </div>
    </div>
</template>

<script>

const axios = require('axios');

export default {
    name:'peermessage',
    props: ['messages', 'user'],
    data: function() {
        return{
            message: '',
            data_messages: [],

        }
    },
    computed: {

    },
    methods: {
      sendMessage() {
            let vm = this;
            console.log(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
            axios.post('/api/peer-peer/message',{
                message: this.message,
                from_user_id: this.user,
                to_user_id: window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
            })
            .then(function (response) {
                // handle success
                vm.data_messages = response.data;
            });

            this.message = '';

      }
    },
    mounted: function () {
        this.data_messages = JSON.parse(this.messages)
        this.$nextTick(function() {
        var container = this.$el.querySelector("#message-box");
        console.log(container)
        container.scrollTop = container.scrollHeight;
        });
    }

}
</script>