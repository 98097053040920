<template>
    <div class="shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50">
        <multiselect
         class="bg-darker border-bottom text-white"
        v-model="selected"
        :options="sortedOptions"
        :multiple="multiple"
        track-by="id"
        :disabled="isDisabled"
        :custom-label="customLabel"
        v-on:input="onChange"
        >
        </multiselect>
        <input v-for="(item, index) in selectedOption" :key="index" type="hidden" :name="fieldName + (multiple ? '[]' : '')" :value="item">
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
    name:'categories',
    components: { Multiselect },
    props: ['fieldName', 'options', 'old', 'disabled', 'inputMultiple'],
    data: function() {
        return{
            multiple: this.inputMultiple ? true : false,
            selected : []
        }
    },
    computed: {
        selectedOption: function () {
            if(Array.isArray(this.selected)){
                var selected = []
                for (var i=0; i < this.selected.length; i++) {
                    if(this.selected[i].id){
                        selected[selected.length] = this.selected[i].id;
                    }
                }
                return selected;
            }
            else{
                if(this.selected.id ){
                    return [this.selected.id]
                }
            }
        },

        sortedOptions: function () {
            var unsortedOptions = JSON.parse(this.options);

            unsortedOptions.forEach(function(unsortedOption) {
                if(unsortedOption.is_maker_category) {
                        unsortedOption.name = unsortedOption.name;
                }
            });
        
            return unsortedOptions;
        },

        isDisabled : function() {
            return this.disabled == 'true';
        }
    },
    methods: {
        customLabel ({ name, is_maker_category}) {
		return name
        },
        onChange: function(selectedOption) {
            this.$store.commit('setId', selectedOption.id)
       }

    },
    mounted: function () {
        var root = this
        if(root.old) {
            var selected = []
            var options = JSON.parse(root.options);
            if (root.multiple) {
                for (var i=0; i < root.old.length; i++) {
                    var option = options.find(function(item) { return Number(item.id) == Number(root.old[i]); })
                    if (option) {
                        selected.push(option);
                        root.$store.commit('setId', options[i])
                    }
                }
            }
            else {
                for (var i=0; i < options.length; i++) {
                    if (Number(options[i].id) == Number(root.old)) {
                        selected.push(options[i]);
                        root.$store.commit('setId', options[i].id)
                    }
                }
            }

            root.selected = selected;
        }
    }

}
</script>
