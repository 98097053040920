<template>

    <div>
            <datetime v-model="dateRange" :max-datetime="inputMaxDate" type="datetime"></datetime>
            <input type="hidden" :value="dateToMysql" :name="name"/>

    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

import * as moment from 'moment';

export default {
    name:'date',
    components: {
        datetime: Datetime
    },
    props: ['date', 'name', 'maxDate'],
    data () {
        let startDate = this.date ? moment(this.date).toISOString() : moment().toISOString();

        let max_date = null
        if (this.maxDate) {
            max_date = this.maxDate
        }

        return {
            dateRange: startDate,
            inputMaxDate: max_date,
        }
    },
    computed: {
        dateToMysql: function () {
            var d = moment(this.dateRange);
            if (d.isValid()) {
                return moment(this.dateRange).format('YYYY-MM-DD HH:mm:ss');
            }
            return '';
        }
    },
    mounted: function () {
    }
}
</script>