 <template>
   <div>
     <editor v-model="content" api-key="cemfgr65ivw46gvn9c69nbvsjeksdhepzjouk75kzh1u8ypu" :init="{        
        height: 500,
        menubar: false,
        toolbar: 'bold italic underline |  undo redo',
        content_css: 'dark',
       }"/>
    <input :name="name" type="hidden" :value="content" />
   </div>
</template>

 <script>
 import Editor from '@tinymce/tinymce-vue'

 export default {
    name: 'wysiwyg',
    props: {
        value: String,
        name: String,
    },
    data: function() {
        return{
            content: "",
        }
    },
    components: {
     'editor': Editor
    },

    mounted: function() {
        if(this.value){
            this.content = this.value
        }
    }
 }
 </script>