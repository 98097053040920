<template>
    <button type="button" @click="showAlert()" :class="buttonClass" v-html="buttonContent"></button>
</template>
<script>
    export default {
		name: 'jqueryconfirmconfirm',
		props: ["alertTitle", "alertReason", "buttonContent", "buttonClass", "dataRoute"],
		methods: {
			showAlert() {
				var dataRoute = this.dataRoute;
				var parent = this;
				$.confirm({
					theme: 'dark',
					content: this.alertReason,
					title: this.alertTitle,
					useBootstrap: false,
					buttons: {
						Yes: {
							btnClass: this.buttonClass,
							action: function() {
								window.location = dataRoute;
							}
						},
						No: {
							btnClass: this.buttonClass,
							action: function() {
								
							}
						}
					}
				});
			}
		}
    }
</script>