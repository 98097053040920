<template>
    <div class="col-12 col-md-2">
        <div class="position-relative border"> 
        <input v-if="'id' in file" type="hidden" name="existing_files[]" :value="file.id"/>
        <input v-else type="file" name="files[]" :id="'files' + fileKey" class="d-none"/>
        <div v-if="!disabled" class="position-absolute end-0 ">
            <div class="border border-danger bg-danger p-3">
            <span :id="'cancel'+ fileKey" @click="deleteFile()" class="">
                <i class="fas fa-trash text-white shadow"></i>
            </span>
            </div>
        </div>
        <a :href="fileUrl()" target="_new">
            <img  v-if="file" class="img-fluid" :src="fileUrl()" />
        </a>
        </div>
    </div>
</template>
<script>

export default {
    name:'imageuploaderitem',
    props: {
        fileKey: {
            type: Number
        },
        file: {
            type: Object,
        },
        disabled: {
            type: String,
            default: false
        },
    },
    data: function() {
        return{}
    },
    mounted: function() {
        if (!('id' in this.file)) {
            var file = new File([this.file], this.file.name, { type: 'mime_type' in this.file ? this.file.mime_type : this.file.type });
            var container = new DataTransfer();

            container.items.add(file);
            document.getElementById('files' + this.fileKey).files = container.files;
        }
    },
    methods: {
        fileUrl() {
            return this.file.path ? '/' + this.file.path : URL.createObjectURL(this.file)
        },
        deleteFile() {
			this.$parent.deleteFile(this.fileKey)
        }
    }
}
</script>