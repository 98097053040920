require('./bootstrap');

const bootstrap = require('bootstrap')

require('alpinejs');
import Vue from 'vue'
import Vuex from 'vuex'
import vuetify from './plugins/vuetify' // path to vuetify export

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    category_id: null
  },
  mutations: {
    setId (state , id) {
      state.category_id = id;
    }
  },
  getters: {
    category_id: state => {
      return  state.category_id
    }
  }
})




import review from './components/review.vue';
import selectmultiple from './components/selectmultiple.vue';
import imageuploaderitem from './components/imageuploaderitem.vue';
import imageuploader from './components/imageuploader.vue';
import singleimage from './components/singleimage.vue';
import termselector from './components/termselector.vue';
import categories from './components/categories.vue';
import date from './components/date.vue';
import wysiwyg from './components/wysiwyg.vue';
import jqueryconfirmconfirm from './components/jqueryconfirmconfirm.vue';
import jqueryconfirmdialog from './components/jqueryconfirmdialog.vue';
import reviewdispatchpanels from './components/reviewdispatchpanels.vue';
import limitedshowpanel from './components/limitedshowpanel.vue';
import peermessage from './components/peermessage.vue';
import vtextarea from './components/vtextarea.vue';

const app = new Vue({
    vuetify,
    el: '#app',
    store,
    components: {
      review,
      selectmultiple,
      termselector,
      categories,
      imageuploaderitem,
      imageuploader,
      singleimage,
      date,
      wysiwyg,
      jqueryconfirmconfirm,
      jqueryconfirmdialog,
      reviewdispatchpanels,
      limitedshowpanel,
      peermessage,
      vtextarea
    }
});

$(".artwork").click(function() {
  window.location = $(this).find("a").attr("href"); 
  return false;
});