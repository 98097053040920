<template>
    <div>
        <!-- Rejection Comment-->
        <div class="mt-4">
            <label>Review</label><br>
            <div class="mt-2">
                <input class="text-primary shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" id="approve" type="radio" name="approval" value="1" v-model="approval">
                <label for="approve" class="font-medium text-sm text-gray-700">{{ acceptLabel }}</label>
            </div>
            <div class="mt-2">
                <input class="text-primary shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" id="approve-changes-required" type="radio" name="approval" value="2" v-model="approval">
                <label for="approve-changes-required" class="font-medium text-sm text-gray-700">Changes recommended</label>
            </div>
            <div class="mt-2">
                <input class="text-primary shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" id="reject" type="radio" name="approval" value="0" v-model="approval">
                <label for="reject" class="font-medium text-sm text-gray-700">Reject</label>
            </div>
        </div>
        <div v-if="approval === '2'  || (changesRecommended )" class="mt-4" >
            <textarea id="changes-required-text" class="block mt-1 w-full shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" type="text" name="changes_recommended" v-model="changesRecommended"> </textarea>
            <label class="text-secondary block font-medium text-sm text-gray-700" for="changes-required-text">Changes recommended notes</label>
        </div>
        <div v-if="approval === '0'  || (commentValue )" class="mt-4" >
            <textarea id="comment" class="block mt-1 w-full shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" type="text" name="comment" v-model="commentValue"> </textarea>
            <label class="text-secondary block font-medium text-sm text-gray-700" for="comment">Rejection Comment</label>
        </div>
        <div class="mt-6 flex flex-row-reverse">
            <button type="submit" class="text-primary border-gray-20 hover:bg-primary hover:text-white border border border-primary px-4 py-2 mx-0 outline-none focus:shadow-outline">Submit</button>
        </div>
    </div>

</template>

<script>
export default {
    name:'review',
    props: ['commentValue', 'acceptLabel', 'changesRecommended'],
    data: function() {
        return{
            approval : null
        }
    }
}
</script>