<template>
    <div>
        <textarea v-model="current" id="description" class="block mt-1 w-full shadow-sm border-gray-200 focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50" :name="name" rows="4"></textarea>
        <label class="float-right text-xs text-secondary">{{wordCount}} <span v-if="max ? true : false"> of {{max}}</span> words  {{ max ?  'maximum' :'' }}</label>
    </div>
</template>

<script>


export default {
    name:'vtextarea',

    props: {
        max: Number,
        old: String,
        name: {
            type: String,
            default:'description'
        }
    },
    data: function() {
        return{
            current: this.old
        }
    },

    computed : {
        wordCount(){
            var vm = this,filecont = vm.current;
            return vm.skip_html && (filecont = filecont.replace(/<\S[^><]*>/gi, "")),
                vm.current.match(/\w+/g) ? vm.current.match(/\w+/g).length : 0;
        }
    },
    mounted: function () {

    }

}
</script>