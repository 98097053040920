import Vue from 'vue'
import Vuetify from 'vuetify'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(Vuetify)
Vue.use(DatetimePicker)


const opts = {    
    theme: {
        themes: {
            light: {
                background: '#FFFFFF',
                surface: '#FFFFFF',
                primary: '#6200EE',
                secondary: '#03DAC6',
                error: '#B00020',
                info: '#2196F3',
                success: '#4CAF50',
            }
        }
    }
}

export default new Vuetify(opts)