<template>
    <div>
        <multiselect class="bg-darker border-bottom text-white"
        v-model="selected"
        :options="sortedOptions"
        :multiple="single == true ? false:true"
        track-by="id"
        :disabled="isDisabled"
        :custom-label="useUserLabel ? userLabel : customLabel"
        >
        </multiselect>
        <template v-for="(option,key) in selectedOptions"> 
        <input :key="key" type="hidden" :name="fieldName" :value="option.id ? option.id : option">
        </template>
        <a v-if="single" class="link-light "  @click="selected = []">Clear selection</a>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
    name:'selectmultiple',
    components: { Multiselect },
    props: ['fieldName', 'options', 'old', 'disabled', 'useUserLabel',  'single'],
    data: function() {
        return{
            selected : []
        }
    },
    computed: {
        selectedOptions: function () {
            let selectedOptions = [];
            if(!Array.isArray(this.selected)){
                if(this.selected.id){
                    selectedOptions.push(this.selected.id);
                }
                else{
                    selectedOptions.push(this.selected);
                }
            }
            else{

                this.selected.forEach((item) => {
                    if(item.id){
                        selectedOptions.push(item.id);
                    }
                    else{
                        selectedOptions.push(item);
                    }
                });
            }
            return selectedOptions;
        },

        sortedOptions: function () {
            var unsortedOptions = JSON.parse(this.options);

            function compare(a, b) {
                if(a.order >= 0) {
                    if(a.order < b.order)
                        return -1; 
                    if(a.order > b.order)
                        return 1
                }
                
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            }

            if(unsortedOptions.length > 0) {
                return unsortedOptions.sort(compare);
            }

            return unsortedOptions;
        },

        isDisabled : function() {
            return this.disabled == 'true';
        }
    },
    methods: {
        customLabel ({ name, title}) {

            return title ? `${title}` : `${name}`
        },

        userLabel ({ name, email }) {
            return `${name} – ${email}`
        },
    },
    mounted: function () {
        if(JSON.parse(this.old)){
            var selected = [];
            this.selected = JSON.parse(this.old);
            var options = JSON.parse(this.options);
            for (var key=0; key < this.selected.length; key++){
                 for (var i=0; i < options.length; i++) {
                    if (Number(options[i].id) == Number(this.selected[key].id) || Number(options[i].id) == Number(this.selected[key]) ) {
                        selected.push(options[i]);
                    }
                }
            };
            this.selected = selected;
        }
    }

}
</script>