<template>
    <div>
        <input :id="name" style="display:none;" :name="name" type="file" ref="file" @change="addFile">
        <div class="my-5">
            <label :for="name" class="w-100 p-3 bg-darker border-0 border-bottom border-light text-white"><i class="fa-solid fa-paperclip"></i> {{ this.file && this.file.name ? this.file.name : "No File Loaded" }}</label>
        </div>
        <div class="my-5 text-start">
            <label :for="name" class="btn btn-outline-light fw-bold text-uppercase rounded-0" >Choose File</label>
        </div>
        <div class="my-5">
            <img v-if="file != null" class="object-cover w-100" :src="fileUrl(file)" />
        </div>
    </div>
</template>

<script>


export default {
    name:'singleimage',

    props: {
        image: String,
        name: {
            type: String,
            default:'asset'
        }
    },
    data: function() {
        return{
            selected : [],
            file: null
        }
    },

    methods: {
        addFile(e) {
            console.log( e.target.files);
            let files = e.target.files ? e.target.files : e.dataTransfer.files;

            ([...files]).forEach(f => {
                this.file = f
            });
        },

        fileUrl(file){
            return file.path ? '/' + file.path : URL.createObjectURL(file)
        },
    },
    mounted: function () {
        console.log(this.image);
        this.file = JSON.parse(this.image);
    }

}
</script>