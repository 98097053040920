<template>
    <div class="row">
        <div class="col-12 col-md-6">
            <div v-if="!disableUploader" data-vuetify>
                <v-file-input light v-model="inputFiles"  name="files_temp[]"  label="File input" multiple placeholder="Select your files" prepend-icon="mdi-paperclip" :show-size="false">
                </v-file-input>
                <small v-if="!disabled" class="-mt-3">Max upload size 2MB</small>
                <input type="hidden" name="deleted_files[]" v-for="(file, key) in deleted_files" :key="'delete-'+key + file" :value="file"/>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="py-5">
                <p>Please ensure your main image is of the artwork itself, not the artwork in-situ. Example...</p>
                    <i class="fas fa-circle-check text-primary"></i>
                    <img src="/artwork-valid-example.png">

                    <i class="fas fa-circle-xmark text-danger"></i>
                    <img src="/artwork-in-situ-example.png">
            </div>
        </div>
        
        <div class="col-12">
            <p v-if="!disabled" class="mt-1 mb-1 text-xs italic">To select multiple pieces of art, hold down CTRL (or CMD on a Mac) and click each piece of art you'd like to upload.</p>
        </div>
        
        <div class="col-12">
            <div id="filesAlreadyUploaded" class="row" v-cloak>
                <p> Image preview(s):</p>
                <imageuploaderitem v-for="(file,key) in files" :key="'file'+key+file.id ? file.id: ''" :file-key="key" class="" :disabled="disabled" :file="file"></imageuploaderitem>
            </div>
        </div>
    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet">
</div>
</template>
<script>
import Input from '../../../vendor/laravel/breeze/stubs/inertia-vue/resources/js/Components/Input.vue';
import imageuploaderitem from './imageuploaderitem.vue';

export default {
  components: { Input, imageuploaderitem },
    name:'imageuploader',
    props: {
        images: String,
        disabled: {
            type: String,
            default: false
        },
        "disableUploader": {
            type: String,
            default: false
        },
    },
    data: function() {
        return{
            selected : [],
            files: [],
            input_files: [],
            deleted: [],
            deleted_files: [],
        }
    },
    computed: {
        inputFiles: {
            get: function() {
                return this.input_files;
            },
            set: function(newValue) {
                if (newValue.length > 0) {
                    var root = this
                    newValue.forEach(element => root.files.push(element) )
                    this.input_files = [];
                }
            }
        }
    },
    methods: {
        addFile(e) {
            let files = e.target.files ? e.target.files : e.dataTransfer.files;
            if(!files) return;
            ([...files]).forEach(f => {
                this.files.push(f);
            });
            return files;
        },
        deleteFile(key){
            if(this.files[key].id){
                this.deleted_files.push(this.files[key].id);
            }
            this.files.splice(key, 1)
        }
    },
    mounted: function () {
         this.files = JSON.parse(this.images);
    }
}
</script>

<style>
[data-vuetify] .theme--light.v-input, [data-vuetify] .theme--light.v-input input, [data-vuetify] .theme--light.v-input textarea {
  color: #ffffff;
}
[data-vuetify] .v-icon.v-icon, [data-vuetify] .theme--light.v-label{
  color: #ffffff;
}
[data-vuetify] .theme--light.v-label {
  color: #ffffff;
}
[data-vuetify] .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: white;
}
[data-vuetify] .theme--light.v-counter {
    color: white;
}
[data-vuetify] .theme--light.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before
{
    color: white;
}

</style>