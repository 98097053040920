<template>
    <button type="button" @click="showAlert" :class="buttonClass" v-html="buttonContent"></button>
</template>
<script>
    export default {
		name: 'jqueryconfirmdialog',
		props: ["alertTitle", "alertReason", "buttonContent", "buttonClass"],
		methods: {
			showAlert() {
				$.dialog({
					content: this.alertReason,
					title: this.alertTitle,
					titleClass: 'h4 pt-0',
				});
			}
		}
    }
</script>