<template>
    <div>
		<!-- I confirm the art was delivered -->
		<div class="mt-4">
			<label for="confirm" v-text="'I confirm the art was delivered'" />
			<input id="confirm" class="block mt-1" type="radio" name="confirm" value="1" required v-model="showRefusePanel" @click="showPanel()"/>
		</div>
		<!-- The art was delivered but I have an issue -->
		<div class="mt-4">
			<label for="issues" v-text="'The art was not delivered or the art was delivered but I have an issue'" />
			<input id="issues" class="block mt-1" type="radio" name="confirm" value="0" required v-model="showRefusePanel" @click="showPanel()"/>
		</div>
		<div v-if="showRefusePanel !== '' && parseInt(showRefusePanel) == 0">
			<!-- Name -->
			<div class="mt-4">
				<label for="buyer_response_name" v-text="'Contact name'" />
				<input id="buyer_response_name" class="block mt-1 w-full" type="text" name="buyer_response_name" v-model="inputBuyerResponseName" required autofocus/>
			</div>

			<!-- Contact email -->
			<div class="mt-4">
				<label for="buyer_response_email" v-text="'Contact email'" />
				<input id="buyer_response_email" class="block mt-1 w-full" type="email" name="buyer_response_email" v-model="inputBuyerResponseEmail" required autofocus/>
			</div>

			<!-- Contact number -->
			<div class="mt-4">
				<label for="buyer_response_phone" v-text="'Contact number'" />
				<input id="buyer_response_phone" class="block mt-1 w-full" type="text" name="buyer_response_phone" v-model="inputBuyerResponsePhone" required autofocus/>
			</div>

			<!-- Issue -->
			<div class="mt-4">
				<label for="buyer_response_issue" v-text="'Issue'" />
				<textarea id="buyer_response_issue" class="block mt-1 w-full" name="buyer_response_issue" required autofocus v-model="inputBuyerResponseIssue"></textarea>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name:'reviewdispatchpanels',
		props: ['confirm','buyerResponseName', 'buyerResponseEmail', 'buyerResponsePhone', 'buyerResponseIssue'],
		data: function() {
			return{
				showRefusePanel: this.confirm,
				inputBuyerResponseName: this.buyerResponseName,
				inputBuyerResponseEmail: this.buyerResponseEmail,
				inputBuyerResponsePhone: this.buyerResponsePhone,
				inputBuyerResponseIssue: this.buyerResponseIssue,
			};
		},
		mounted() {
			this.showPanel(this.showRefusePanel);
		},
		methods: {
			showPanel() {
				if (this.showRefusePanel !== '' && parseInt(this.showRefusePanel) == 0) {
					this.inputBuyerResponseName = ''
					this.inputBuyerResponseEmail = ''
					this.inputBuyerResponsePhone = ''
					this.inputBuyerResponseIssue = ''
				}
			}
		}
	}
</script>
