<template>
    <div>

    <label class="m-0 px-1 form-label d-block text-small bg-darker fs-6" v-if="this.type == 'Orientation'">{{this.$store.state.category_id > 4 && this.$store.state.category_id != 10   ? 'Location' : 'Orientation'}}<span class="text-danger text-italic"> *</span></label>

    <div class="w-100 bg-darker border-0 border-bottom border-light text-white">
        <multiselect
        v-model="selected"
        :options="categoryOptions"
        :multiple="!single"
        track-by="id"
        :disabled="isDisabled"
        :custom-label="customLabel"
        >
        </multiselect>

        <input v-for="(option,key) in selectedOptions" :key="key" type="hidden" :name="fieldName" :value="option">
    </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from "vuex";

export default {
    name:'termselector',
    components: { Multiselect },
    props: [ 'terms', 'type', 'fieldName', 'old', 'disabled', 'single'],
    data: function() {
        return{
            selected : [],
            oldSet: false
        }
    },
    watch: {
        "category_id": {
            deep: true,
            handler(newCount, oldCount) {
                if(oldCount != null) {
                    this.selected = []
                }
                this.setOld()
            }
        },
    },
    computed: {
        ...mapGetters(["category_id"]),
        selectedOptions: function () {
            let selectedOptions = [];

            if (this.single  && this.selected) {
                selectedOptions = [this.selected.term_id]
            }
            else{
                this.selected.forEach((item) => {
                    if(item.term_id){
                        selectedOptions.push(item.term_id);
                    }
                    else{
                        selectedOptions.push(item);
                    }
                });
            }


            return selectedOptions;
        },

        isDisabled : function() {
            return this.disabled == 'true' || this.categoryId == null;
        },
        categoryId: function () {
            return this.$store.state.category_id;
        },
        categoryOptions: function () {
            var unsortedTerms = this.categoryId != null ? JSON.parse(this.terms)[this.categoryId][this.type] : [];

            function compare(a, b) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            }

            if(unsortedTerms.length > 0) {
                return unsortedTerms.sort(compare);
            }

            return unsortedTerms;
        }
    },
    methods: {
        customLabel ({ name}) {
            return `${name}`
        },

        setOld(){
            if(JSON.parse(this.old) && !this.oldSet){
                var selected = [];
                var options =  JSON.parse( this.terms)[this.$store.state.category_id][this.type] ;

                let selectedOld = JSON.parse(this.old);

                if (this.single == true) {
                    for (var i=0; i < options.length; i++) {
                        if (Number(options[i].term_id)  == Number(selectedOld.term_id) || Number(options[i].term_id)  == Number(selectedOld)) {
                            selected = options[i];
                        }
                    }
                } else {
                    for (var key=0; key < selectedOld.length; key++){
                        for (var i=0; i < options.length; i++) {
                            console.log(selectedOld)
                            if (Number(options[i].term_id) == Number(selectedOld[key].term_id) || Number(options[i].term_id) == Number(selectedOld[key]) ) {
                                selected.push(options[i]);
                            }
                        }
                    };
                }

                this.selected = selected;
                this.oldSet = true;

            }
        }
    },

}
</script>